import { initializeApp } from "firebase/app"
import { collection, getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyCwaCNLWcqtZVaAfAU80VEolM5iec2-L_I",
  authDomain: "ia-bet.firebaseapp.com",
  projectId: "ia-bet",
  storageBucket: "ia-bet.appspot.com",
  messagingSenderId: "141556199031",
  appId: "1:141556199031:web:7bc08019b89dee21b3e072",
  measurementId: "G-6WHPX3H7F4",
}

const appConfig = initializeApp(firebaseConfig)

export const db = getFirestore(appConfig)
export const resultsCollectionRef = collection(db, "blazeDoubleResults")
export const blazeDoubleStrategiesRef = collection(db, "blazeDoubleStrategies")
export const blazeCrashStrategiesCollection = collection(
  db,
  "blazeCrashStrategies"
)

export const apostaGanhaCrashStrategiesCollection = collection(
  db,
  "apostaGanha",
  "crash",
  "strategies"
)

export const apostaGanha2AviatorStrategiesCollection = collection(
  db,
  "apostaGanha2",
  "crash",
  "strategies"
)

export const blazeDoubleStrategyAnalytics = collection(
  db,
  "blaze",
  "double",
  "strategies"
)
